<template>
  <div class="orderDetail mt-3">
    <div class="d-flex justify-content-start align-items-center mb-3">
      <div class="d-flex align-items-center">
        <div style="cursor: pointer;" @click.prevent="$emit('goBack')"><i class="fe fe-arrow-left font-size-16 mr-2" /></div>
        <h3 class="m-0">{{ $t("purchase.detail.title") }}</h3>
      </div>
    </div>
    <a-row>
      <a-col :xs="24" :sm="24" :md="16" class="px-2 py-2">
        <a-row>
          <a-col :span="24" class="payment-information-not-found px-3 py-3">
            <a-row
              type="flex"
              wrap
              :justify="isMobileView ? 'space-around' : 'center'"
            >
              <div
                class="d-flex align-items-center flex-column justify-content-between w-25"
                v-for="({ title, active, component, code }, key) in listProcess"
                :key="key"
              >
                <div>
                  <component
                    v-bind:is="component"
                    :key="key"
                    :active="active"
                  ></component>
                </div>
                <div
                  class="mt-2"
                  :class="
                    active
                      ? code.includes('CANCELED')
                        ? 'text-cancel'
                        : 'text-icon'
                      : 'text-icon-pending'
                  "
                >
                  {{ title }}
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-col>

      <div class="d-flex flex-column justify-content-center align-items-center w-100">
        <div class="mt-5 mb-4">
          <EmptyIcon />
        </div>
        <div style="color: #000000" class="ml-2 desc-empty-state text-center">Belum ada transaksi yang dilakukan. Mulai melakukan transaksi sekarang untuk melihat riwayat transaksi Anda.</div>
      </div>
    </a-row>
  </div>
</template>

<script>
import OrderDetailIcon from '@/components/Icons/DetailOrder.vue'
import OrderIcon from '@/components/Icons/Order.vue'
import ProcessIcon from '@/components/Icons/Process.vue'
import SendingIcon from '@/components/Icons/Sending.vue'
import OrderCancelIcon from '@/components/Icons/OrderCancel.vue'
import BoxIcon from '@/components/Icons/Box.vue'
import CopyIcon from '@/components/Icons/Copy.vue'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import Upload from '@/components/Icons/Upload.vue'
import Vue from 'vue'
import PotensialCoins from '@/components/Purchase/Checkout/PotensialCoins/index.vue'
import EmptyIcon from '@/components/Icons/Empty.vue'

const staticListOrderProcess = [
  {
    title: 'Menunggu Konfirmasi',
    code: ['WAITING_CONFIRMATION', 'SUBMITTED', 'VERIFIED'],
    active: false,
    component: Vue.extend(OrderIcon),
  },
  {
    title: 'Dalam Proses',
    code: ['ON_PROCESS', 'ACCEPTED'],
    active: false,
    component: Vue.extend(ProcessIcon),
  },
  {
    title: 'Pengiriman',
    code: ['SHIPPED', 'DELIVERED', 'BUYER_ACCEPTED'],
    active: false,
    component: Vue.extend(SendingIcon),
  },
  {
    title: 'Selesai',
    code: ['COMPLETED'],
    active: false,
    component: Vue.extend(BoxIcon),
  },
]
const cancelReason = [
  'Ingin mengubah alamat pengiriman',
  'Ingin memasukkan atau mengubah poin yang digunakan',
  'Ingin mengubah pesanan',
  'Ingin mengubah cara pembayaran',
  'Lainnya',
]
export default {
  name: 'track-result',
  components: {
    OrderDetailIcon,
    OrderIcon,
    ProcessIcon,
    SendingIcon,
    OrderCancelIcon,
    BoxIcon,
    CopyIcon,
    Upload,
    WarehousePinIcon,
    PotensialCoins,
    EmptyIcon,
    // PurchaseDetailComponent
  },
  data() {
    return {
      data: {},
      payment: null,
      modalVisible: false,
      modalCancel: false,
      image_struck: {
        file: '',
        url: '',
        fileList: [],
      },
      previewImage: false,
      payment_type: '',
      listProcess: staticListOrderProcess,
      warehouseDetail: null,
      selectedCancelReason: null,
      listCancelReason: cancelReason,
      discountTransaction: [],
      discountProduct: [],
      dataMixPayment: null,
      flagBuyerAccepted: true,
      localPointUsed: 0,
      creditLimitUsage: 0,
      dataDiscount: [],
      dataPercentage: [],
      dataTransactionType: [],
      cadBillStatus: null,
      infoReturn: {},
      potensialPromotion: [],
      voucherUsed: [],
    }
  },
  props: {
    detailOrder: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    loading: function () {
      return this.$store.state.purchase.loading
    },
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
    checkout_status() {
      return this.data.status
    },
    pointUsed() {
      return this.$store.state.loyalty.usedPoints
    },
  },
  methods: {
  },
  created: function () {
    this.fetchOrder(this.detailOrder)
    console.log(this.detailOrder)
  },
}
</script>

<style lang="scss">
.orderDetail {
  &-icon {
    background: #fff !important;
    padding: 6px 11px;
    border-radius: 50%;
  }

  .payment-information-not-found {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    opacity: 0.4;
    .confirm-payment {
      height: 33px;
      background: #ffffff;
      border: 1px solid #ed1c24 !important;
      // border-width: 1px;
      // border-color: #265C9B;
      border-radius: 5px;
      color: #ed1c24;
      cursor: pointer;
    }

    .status-icon-done {
      background: #015ca1;
      width: 60px;
      height: 60px;
      border-radius: 100px;
    }

    .status-icon-done-mobile {
      background: #015ca1;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    .status-icon-pending {
      background: #d2d2d2;
      border-radius: 100px;
      width: 60px;
      height: 60px;
    }

    .status-icon-pending-mobile {
      background: #d2d2d2;
      border-radius: 100px;
      width: 40px;
      height: 40px;
    }

    .text-icon {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ca5256;
    }

    .text-icon-mobile {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      color: #ca5256;
    }

    .text-icon-pending {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #767676;
    }

    .text-cancel {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ff0000;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #000000;
    }

    .title-2 {
      font-family: Roboto;
      font-style: normal;
      font-weight: light;
      font-size: 14px;
      line-height: 16px;
      color: #2b2b2b;
    }

    .total-payment {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #ed1c24;
    }

    .status-payment-paid {
      background: rgb(72, 187, 229) !important;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 4px;
      font-size: 14px;
    }

    .status-payment-unpaid {
      font-size: 14px;
      background: #FA8F8F;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 4px;
    }

    .status-payment-cancel {
      font-size: 14px;
      background: #FA8F8F;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      width: 25%;
      border-radius: 4px;
    }
  }
}

.desc-empty-state {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 400px;
}
</style>
