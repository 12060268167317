var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cui__uncontent"},[_c('div',{staticClass:"biz-container"},[(!_vm.isLoading)?_c('div',{staticClass:"container-track"},[(!_vm.detailOrder)?_c('button',{staticClass:"btn-secondary rounded-lg py-1 px-3 mb-4 biz-flex md:biz-hidden align-items-center justify-content-center",on:{"click":function($event){$event.preventDefault();return (() => _vm.$router.go(-1)).apply(null, arguments)}}},[_c('i',{staticClass:"fe fe-arrow-left font-size-16 mr-2"}),_vm._v("Kembali Ke Beranda")]):_vm._e(),(!_vm.detailOrder)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"card-track-form"},[_c('div',{staticClass:"track-header"},[_c('p',{staticClass:"text-title"},[_vm._v("Lacak Pesananmu Disini")]),_c('p',{staticClass:"text-desc"},[_vm._v("Masukkan alamat email dan nomor order untuk melakukan track status pesanan Anda")]),_c('a-form',{staticClass:"mb-2",attrs:{"form":_vm.formTrack},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-2"},[_c('label',{staticClass:"text-left label-form"},[_vm._v("Email")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'guestemail',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Email is required',
                      },
                      {
                        message: 'Invalid email address format',
                        pattern: new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
                      },
                    ]
                  },
                ]),expression:"[\n                  'guestemail',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Email is required',\n                      },\n                      {\n                        message: 'Invalid email address format',\n                        pattern: new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')\n                      },\n                    ]\n                  },\n                ]"}],class:_vm.$style.container,attrs:{"size":"large","placeholder":"masukan email"}})],1),_c('a-form-item',[_c('label',{staticClass:"text-left label-form"},[_vm._v("Nomor Order")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'ordernumber',
                  {
                    rules: [{
                        required: true,
                        message: 'order number is required',
                    },]
                  },
                ]),expression:"[\n                  'ordernumber',\n                  {\n                    rules: [{\n                        required: true,\n                        message: 'order number is required',\n                    },]\n                  },\n                ]"}],class:_vm.$style.container,staticStyle:{"text-transform":"uppercase"},attrs:{"size":"large","placeholder":"..."}})],1),_c('a-button',{staticClass:"btn-submit btn-primary text-center w-100",attrs:{"htmlType":"submit","disabled":!_vm.formTrack.getFieldValue('guestemail') || !_vm.formTrack.getFieldValue('ordernumber'),"size":"large","loading":_vm.isLoading}},[_c('strong',[_vm._v(" Lacak Pesananmu ")])])],1)],1)])]):_c('div',[(_vm.isExist)?_c('TrackResult',{attrs:{"detailOrder":_vm.detailOrder},on:{"goBack":function($event){_vm.detailOrder = null}}}):_c('TrackResultNotFound',{attrs:{"detailOrder":_vm.detailOrder},on:{"goBack":function($event){_vm.detailOrder = null}}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }