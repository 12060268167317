<template>
  <div class="cui__uncontent">
    <div class="biz-container">
      <div v-if="!isLoading" class="container-track">
        <button v-if="!detailOrder" @click.prevent="() => $router.go(-1)" class="btn-secondary rounded-lg py-1 px-3 mb-4 biz-flex md:biz-hidden align-items-center justify-content-center"><i class="fe fe-arrow-left font-size-16 mr-2" />Kembali Ke Beranda</button>
        <div v-if="!detailOrder" class="d-flex justify-content-center">
          <div class="card-track-form">
            <div class="track-header">
              <p class="text-title">Lacak Pesananmu Disini</p>
              <p class="text-desc">Masukkan alamat email dan nomor order untuk melakukan track status pesanan Anda</p>
              <a-form class="mb-2" :form="formTrack" @submit="handleSubmit">
                <a-form-item class="mb-2">
                  <label class="text-left label-form">Email</label>
                  <a-input size="large" placeholder="masukan email" :class="$style.container" v-decorator="[
                    'guestemail',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Email is required',
                        },
                        {
                          message: 'Invalid email address format',
                          pattern: new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')
                        },
                      ]
                    },
                  ]" />
                </a-form-item>
                <a-form-item>
                  <label class="text-left label-form">Nomor Order</label>
                  <a-input size="large" style="text-transform:uppercase" placeholder="..." :class="$style.container" v-decorator="[
                    'ordernumber',
                    {
                      rules: [{
                          required: true,
                          message: 'order number is required',
                      },]
                    },
                  ]" />
                </a-form-item>
                <a-button htmlType="submit" :disabled="!formTrack.getFieldValue('guestemail') || !formTrack.getFieldValue('ordernumber')" size="large" class="btn-submit btn-primary text-center w-100" :loading="isLoading">
                  <strong> Lacak Pesananmu </strong>
                </a-button>
              </a-form>
            </div>
          </div>
        </div>
        <div v-else>
          <TrackResult v-if="isExist" :detailOrder="detailOrder" @goBack="detailOrder = null"/>
          <TrackResultNotFound v-else :detailOrder="detailOrder" @goBack="detailOrder = null"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TrackResult from '@/views/Order/track/track-result.vue'
import TrackResultNotFound from '@/views/Order/track/track-result-not-found.vue'

export default {
  name: 'track-order',
  components: {
    TrackResult,
    TrackResultNotFound,
  },
  data: function () {
    return {
      isLoading: false,
      formTrack: this.$form.createForm(this),
      detailOrder: null,
      isExist: false,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.formTrack.validateFields((err, values) => {
        const payload = {
          order_number: values?.ordernumber?.toUpperCase(),
          email: values?.guestemail,
          channel_id: this.$store.state.app.channel_id,
        }
        if (!err) {
          this.$store.dispatch('purchase/GETGUESTORDERDETAIL', payload)
            .then(({ data }) => {
              this.isExist = true
              this.detailOrder = data
            })
            .catch((err) => {
              if (err.response) {
                this.isExist = false
                this.detailOrder = err.response
                const { data } = err.response
                this.$notification.error({
                  message: data && data.error ? data.error : 'Internal Server Error',
                })
              }
            })
        }
      })
    },
    emailValidator(rule, value, callback) {
      console.log({ rule, value })
      if (value === '') {
        callback(new Error(`${this.$i18n.t('customer.rules.email')}`))
      } else {
        const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (value.match(mailformat)) {
          callback(new Error('pfft'))
        } else {
          callback(new Error('haha'))
        }
      }
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss" scoped>
.container-track {
}
.btn-secondary {
  background-color: #FFFFFF;
  color: #000000;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  text-align: left;

  &:hover {
    background-color: #e2e2e2;
    color: #000000;
  }

}
.track-header {
  .text-title {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
  .text-desc {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #777777;
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}
.label-form {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8B8B8B;
}
.btn-primary {
  background-color: #000000;
  color: #FFFFFF;
  border-color: #000000;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &:hover:enabled {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border-color: #000000 !important;
  }
  &:active {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
  &:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #000000 !important;
  }
}

.card-track-form {
  width: 100%;
  margin-top: 0px;

  @include media-breakpoint-up(md) {
    width: 500px;
    margin-top: 3rem;
    margin-bottom: 5rem;
    padding: 2rem;
    box-shadow: 0 5px 25px -10px rgba(0, 0, 0, .2);
  }
}
</style>
